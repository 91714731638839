import React from 'react'

import { Landing } from '../../components/common'
import { Social } from '../../components/common/Social'

// Styles
import '../../styles/app.css'

const Index = ({ data, location, pageContext }) => {
    return (
        <>
            <Landing>
                <div className="container mt-2">
                    <h1>Controlla la tua email <span role="img" aria-label="mail">📩</span></h1>
                    <p>A breve riceverai una email con ulteriori istruzioni <span role="img" aria-label="party emoji">🥳</span></p>
                </div>
                <div className="flex-container">
                    <div className="flex-item">
                        <h3>Sostieni NorthFPV</h3>
                        <p>Se pensi che il nostro lavoro sia utile, <a href="/supportaci">ecco come puoi sostenerci.</a></p>

                        <a className="my-btn btn-cta" href="/">Torna al sito</a>
                    </div>
                    <div className="flex-item">
                        <div className="social-list-container">
                            <h3>Seguici sui social</h3>
                            <Social isWhite={false}/>
                        </div>
                    </div>
                </div>
            </Landing>
        </>
    )
}

export default Index;
